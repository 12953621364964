import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

import Heading from 'components/shared/heading';
import HubspotForm from 'components/shared/hubspot-form';

import { FORM_IDS } from 'constants/form';

import sendImpactEvent from 'utils/send-impact-event';
import styles from './hubspot-form-wrapper.module.scss';

const cx = classNames.bind(styles);

const IMPACT_EVENT_ID = 31876;

const HubspotFormWrapper = ({
  title,
  description,
  titleTextSize,
  descriptionTextSize,
  formId,
  id,
}) => {
  const impactParams = {};

  const onFormSubmit = (e) => {
    const orderId = `OID-${Math.floor(Math.random() * Date.now())}`;
    const customerEmail = e.querySelector('input[name="email"]').value;
    const orderPromoCode = e.querySelector('input[name="promo_code"]').value || '';

    // hidden fields in Hubspot form
    const inputOrderId = e.querySelector(`.hs-input[name="ir_order_id"]`);
    const inputReferralCode = e.querySelector(`.hs-input[name="ir_referral_code"]`);

    impactParams.orderId = orderId;
    impactParams.customerId = '';
    impactParams.customerEmail = customerEmail;
    impactParams.orderPromoCode = orderPromoCode;

    inputOrderId.value = orderId;
    inputReferralCode.value = orderPromoCode;
  };

  const onFormSubmitted = () => {
    sendImpactEvent(IMPACT_EVENT_ID, impactParams);
  };

  return (
    <section className={cx('wrapper')} id={`${id}Wrapper`}>
      <div className="container">
        {(title || description) && (
          <div className={cx('head')}>
            {title && (
              <Heading className={cx('title')} tag="h4" size={titleTextSize}>
                {title}
              </Heading>
            )}
            {description && <p className={cx('description', descriptionTextSize)}>{description}</p>}
          </div>
        )}
        <div className={cx('form-wrapper')}>
          {formId === FORM_IDS.REQUEST_A_DEMO ||
          formId === FORM_IDS.REQUEST_A_QUOTE ||
          formId === FORM_IDS.CONTACT_US ? (
            <HubspotForm
              formId={formId}
              targetId={id}
              onFormSubmit={onFormSubmit}
              onFormSubmitted={onFormSubmitted}
            />
          ) : (
            <HubspotForm formId={formId} targetId={id} />
          )}
        </div>
      </div>
    </section>
  );
};

HubspotFormWrapper.defaultProps = {
  title: null,
  description: null,
  formId: FORM_IDS.CONTACT_US,
  id: 'hubspotContactForm',
  titleTextSize: 'xxl',
  descriptionTextSize: 'small',
};

HubspotFormWrapper.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  formId: PropTypes.oneOf(Object.values(FORM_IDS)),
  id: PropTypes.string,
  titleTextSize: PropTypes.oneOf(['xxl', 'xl', 'lg', 'md']),
  descriptionTextSize: PropTypes.oneOf(['small', 'medium', 'large', 'extras-large']),
};

export default HubspotFormWrapper;
